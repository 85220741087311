<mat-sidenav-container class="container">
    <mat-sidenav class="sidenav" mode="side" opened fixedInViewport="true" fixedTopGap="64">
        <mat-nav-list class="nav-list">
            <a mat-list-item>
                <i class="material-icons">
                    tv
                </i> Panel
            </a>
            <a mat-list-item>
                <i class="material-icons">
                    settings
                </i> Settings
            </a>
        </mat-nav-list>
    </mat-sidenav>
</mat-sidenav-container>